import React from "react";
import { Link } from "gatsby";

import AboutMarieStyles from "./aboutMarie.module.css";

import HeaderWithTextAndImage from "../components/headerWithTextAndImage";
import HeaderWithTextAndImageSmall from "../components/headerWithTextAndImageSmall";
import Head from "../components/head";
import caretRight from "../images/caret-right.png";


import { graphql } from "gatsby";

export const query = graphql`
  query {
    allContentfulAboutMariePage {
      edges {
        node {
          section1Image {
            file {
              url
            }
          }
          section1Headline
          section1Paragraph {
            section1Paragraph
          }
          section2Image {
            file {
              url
            }
          }
          section2Headline
          section2Paragraph {
            section2Paragraph
          }
          section3Image {
            file {
              url
            }
          }
          section3Headline
          section3Paragraph {
            section3Paragraph
          }
          section4Image {
            file {
              url
            }
          }
          section4Headline
          section4Paragraph {
            section4Paragraph
          }
          section5Image {
            file {
              url
            }
          }
          section5Headline
          section5Paragraph {
            section5Paragraph
          }
        }
      }
    }
  }
`;

class aboutMarie extends React.Component {
  componentDidMount() {
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      let p1 = v.replace(
        "The Secret Sits Pro Musica Ensembles, Inc.",
        "<span style=font-style:italic; >The Secret Sits Pro Musica Ensembles, Inc.</span>"
      );
      el.innerHTML = p1;
    });
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      let p1 = v.replace(
        "The Secret Sits,",
        "<span style=font-style:italic;>The Secret Sits,</span>"
      );
      el.innerHTML = p1;
    });
    Array.from(document.querySelectorAll("p")).forEach(el => {
      let v = el.innerHTML;
      let p1 = v.replace(
        "Better Music",
        "<strong style=color:#ba0c2f; font-weight:600>Better Music</strong>"
      );
      el.innerHTML = p1;
    });
  }
  render() {
    return (
      <>
      <Head title="About Marie"/>
        <HeaderWithTextAndImage
          paragraphHeader={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section1Headline
          }
          imgRight={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section1Image.file.url
          }
          isFirstElement={true}
          lightBackground={true}
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulAboutMariePage.edges[0].node
                .section1Paragraph.section1Paragraph
            }
          </p>
        </HeaderWithTextAndImage>

        <HeaderWithTextAndImageSmall
          isFirstElement={false}
          paragraphHeader={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section2Headline
          }
          imgRight={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section2Image.file.url
          }
          lightBackground={true}
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulAboutMariePage.edges[0].node
                .section2Paragraph.section2Paragraph
            }
          </p>
        </HeaderWithTextAndImageSmall>

        <HeaderWithTextAndImage
          lightBackground={true}
          isFirstElement={false}
          paragraphHeader={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section3Headline
          }
          imgRight={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section3Image.file.url
          }
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulAboutMariePage.edges[0].node
                .section3Paragraph.section3Paragraph
            }
          </p>
          <div
            style={{
              display: "flex",
              justifyContent: "flex-start",
            }}
          >
            <Link
              to="/the-gift-of-alzheimers"
              style={{
                textDecoration: "none",
                color: "#ba0c2f",
                fontWeight: "600",
                display: "flex",
                alignItems: "center",
              }}
            >
              Marie's last's
              <img
                src={caretRight}
                style={{ height: "9px", marginLeft: "4px", width: "auto" }}
              ></img>
            </Link>
          </div>
        </HeaderWithTextAndImage>

        <HeaderWithTextAndImage
          isFirstElement={false}
          paragraphHeader={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section4Headline
          }
          imgRight={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section4Image.file.url
          }
          lightBackground={true}
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulAboutMariePage.edges[0].node
                .section4Paragraph.section4Paragraph
            }
          </p>
        </HeaderWithTextAndImage>
        <HeaderWithTextAndImage
          isFirstElement={false}
          lightBackground={true}
          paragraphHeader={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section5Headline
          }
          imgRight={
            this.props.data.allContentfulAboutMariePage.edges[0].node
              .section5Image.file.url
          }
        >
          <p style={{ whiteSpace: "pre-wrap" }}>
            {
              this.props.data.allContentfulAboutMariePage.edges[0].node
                .section5Paragraph.section5Paragraph
            }
          </p>
        </HeaderWithTextAndImage>

        <section
          style={{ backgroundColor: "#fff" }}
          className={AboutMarieStyles.outroSection}
        >
          <div>
            <iframe
              id={AboutMarieStyles.videoAttr}
              src="https://www.youtube.com/embed/yHM0ow6Pc_M"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
            <p>
              Gentle Marie, it has been a privilege knowing and loving you this
              life.
            </p>
          </div>
        </section>
      </>
    );
  }
}
export default aboutMarie;
