import React from "react";

import hwtisStyles from "./headerWithTextAndImageSmall.module.css";

const headerWithTextAndImage = props => {
  return (
    <section
      className={hwtisStyles.hwtiSection}
      style={
        props.lightBackground === true
          ? props.noPadding === true
            ? { backgroundColor: "#fff", padding: "0px" }
            : { backgroundColor: "#fff" }
          : props.noPadding === true
          ? { padding: "0px" }
          : null
      }
    >
      {props.paragraphHeader !== false ? (
        <div>
          {" "}
          <h2>{props.paragraphHeader}</h2>
        </div>
      ) : null}
      <div className={hwtisStyles.hwtiWrapper}>
        <div className={hwtisStyles.copyContainer}>
          <div className={hwtisStyles.hwtiCopy}>{props.children}</div>
        </div>
        <div className={hwtisStyles.hwtiImgContainer}>
          <img src={props.imgRight}></img>
        </div>
      </div>
    </section>
  );
};
export default headerWithTextAndImage;
